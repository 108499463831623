import React, { useState, useEffect } from "react"
import {
    Layout, 
    MainContainer, 
    NewsItem,    
} from '../app/components'
import Axios from 'axios'

export default (props) => {    
   const [ loading, setLoading ] = useState(true)
   const [ news, setNews ] = useState([])

   useEffect( () => {
        const fetchNews = async () => {
            const response = await Axios.get('https://newsapi.org/v2/top-headlines?country=mx&apiKey=f944de61f06c4a9a878e0ec0c5030ce6')             
            if ( response.status === 200 ) {
                const items = response.data.articles.map(item =>{
                    return {
                        title: item.title,
                        date: new Date(item.publishedAt).toLocaleString(),
                        body: item.content,
                        image: item.urlToImage,
                        url: item.url
                    }
                })                
                setNews(items);
                setLoading(false);                
            }     
        }
        fetchNews();
    },[])
    if (loading) {
        return(       
            <Layout location={props.location}>  
                <MainContainer>
                   <div>loading</div> 
                </MainContainer> 
            </Layout>                                      
        )
    }
    return(       
        <Layout location={props.location}>  
            <MainContainer>
              {news.map(item=><NewsItem  {...item} />  )}
            </MainContainer> 
        </Layout>                                      
    )
    
}
